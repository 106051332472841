export const getEmail = () => {
    const email = sessionStorage.getItem('email');
    return (email);
}
export const setEmail = (email) => {
    sessionStorage.setItem('email', email);
}

export const clearEmailToken = () => {
    sessionStorage.removeItem('email');
}


export const getAccessToken = () => {
    const token = sessionStorage.getItem('token');
    console.log('token', token)
    return (token);
}
export const setAccessToken = (token) => {
    sessionStorage.setItem('token', token);
}

export const clearAccessToken = () => {
    sessionStorage.removeItem('token');
}