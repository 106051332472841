import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './AppWrapper';
import { BrowserRouter } from 'react-router-dom';

import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'


const BaseApp = () => {
    return (
        <BrowserRouter>
            <AppWrapper />
        </BrowserRouter>
    );
};

ReactDOM.render(
    <BaseApp />
    , document.getElementById('root'),
);